export const masterSlaveConnectionText = `Ühenda X1 pistik koos slave kontrolleriga osonaatori külge. \n
Osonaatorist aktiveeri Slave seade nii: Testing menu -> Activate slave devices -> vali "Activate 1 slave".\n
Peamenüüsse tekivad read "Device state" ja "OK messages". Kui OK messages numbrid kasvavad ja Device state näitab olekut "Active", siis on test läbitud ning Master-Slave ühendus seadme vahel toimib.\n
Pärast seda vali, et tal ei ole Slave seadmeid: Testing menu -> Activate slave devices -> vali "No slaves".`;

export const pressureReadingText = `Ühendada osonaatori miinus (-) rõhu mõõteotsik voolikuga testkambri küljes olevasse mõõteotsikusse.\n
Mõõda rõhku samas punktis kalibreeritud rõhuanduriga ning võrdle erinevust. Mõõteviga võib olla maksimaalselt +- 2 Pa.`;

export const systemWorkPermissionText = `Seadista osonaatori programmis tööloa kontrollimist järgmiselt: Testing menu -> Work permission settings -> vali "In use". \n
Test menüüsse tekkis rida Work permission. \n
Kui seina peal oleval testimis karbil lülitada tööluba ON olekusse, peab näitama LCD-s Work permission real "Enabled" ning kui lüliti on OFF olekus, näitab LCD-s "Disabled".\n
Kui toimib, vali Work permission settingus -> Not in use.`;

export const ozonatorCalibrationText = `Alusta osonaatori kalibreerimist järgmiselt: Testing menu -> Calibrate ozone unit -> vali "ON". \n
Pärast seda teeb osonaator kalibreerimist 3 minutit ja kui selle jooksul alarme ei tekkinud on test sooritatud. \n
Kui tekkis mingisugune alarm, tee igaksjuhuks uuesti kalibreerimine. \n
Kui tekkis jälle alarm HX1, HX2 või HX3 kohta, tuleb kontrollida see osoonigeneraator üle ja vajadusel välja vahetada. \n
Kalibreerimise lõpetades liigu tagasi peamenüüsse. \n
Kui vahetasid välja mõne osoonigeneraatori peab välja vahetama ka seerianumbri mis varem sisestasid. \n
Kui pidid tegema uuesti kalibratsiooni, kirjuta see kommentaarina märkuse reale`;

export const controlPanelCheckText = `Lülita osonaator tööle: Testing menust vali System state -> "ON". \n
Osonaator käivitus ja hetke pärast vali System state > OFF. \n
Kui OZ lülitub sisse ja välja ilma probleemideta on test tehtud.`;

export const thermometerCheckText = `Kui OZ töötab, mine Testing menu -> Temperature. 
\nKui osonaatori töötamise ajal temperatuuri näitab ja näit tõuseb siis on test sooritatud.`

export const operationStatusCheckText = `Kui OZ on välja lülitatud, minna Testing menu -> Operation status -> Manual -> On. 
\nTestimise karbil peab minema BIT0 LED põlema. Kui toimib, siis panna Operation status tagasi manuali pealt automaatse peale.`

export const criticalErrorCheckText = `Kui OZ on välja lülitatud, minna Testing menu -> Critical error -> Manual -> Alarm. 
\nTestimise karbil peab minema BIT1 LED põlema. Kui toimib, siis panna Critical error tagasi manuali pealt automaatse peale.`

export const serviceOutputCheckText = `Kui OZ on välja lülitatud, minna Testing menu -> Service -> Manual -> Alarm. 
\nTestimise karbil peab minema BIT2 LED põlema. Kui toimib, siis panna Service tagasi manuali pealt automaatse peale.`;

export const longTestText = `Pane OZ 4.0 tööle osoonikambrisse. \n
Pikk test kestab vähemalt 4 tundi. \n
\nKui selle aja jooksul mingisugseid alarme ei tekkinud siis on test sooritatud. 
\nKui vahepeal tekkisid mingid alarmid või vead, tuleb need vea parandada. 
\nKui mõni osoonigeneraator lõpetas töötamise, tuleb see välja vahetada uue vastu ja sama pikk test uuesti läbi teha. 
\nKui vahetasid välja mõne osoonigeneraatori peab välja vahetama ka seerianumbri mis varem sisestasid. 
\nKui midagi läks valesti ja pidid tegema uuesti testi, kirjuta see kommentaarina märkuse reale`;

export const ozoneGeneratorSafetyInstructions = `1. Testimisel kasutada puhtaid kindaid
\n2. Koroonaplaati ei tohi paljaste kätega katsuda
\n3. Kasuta UV kiirguse kindlaid kaitseprille
\n4. Pinge all osoonigeneraatorid katsuda ei tohi
\nTestimise juhend:
\n1. Võta seade pakendist välja ning kontrolli, et ei oleks nähtavaid täkkeid ja joote kohad plaadil on terved
\n2. Paigalda osoonigeneraator osoonikambrisse
\n3. Ühenda generaatori juhtmed wago pistikutega võimsuse ja voolumõõtjasse
\n4. Lase osoonigeneraatoril töötada 2 minutit enne kui hakkad sisestama selle väärtuseid
\n5. Kontrolli, et vähemalt 90% sinistest plaatidest põleks (kui ühel koroonaplaadil on rohkem kui kahe segmendi suurune pind kustus, siis on toode praak)
\n6. Sisesta voolu ja võimsuse väärtused TestIQ-sse
\n7. Lülita seade välja ning kleebi talle peale seerianumber ja pakenda ära`;
