import { apiUrl } from "../../config/apiconfig";
import { AhuElectricalMeasurements } from "../../types/ahu/AhuElectricalMeasurements";
import { Kalorifeer } from "../../types/kalorifeer/Kalorifeer";
import { DuctRigidity, DuctTestType } from "../../types/DuctTestType";
import { instance } from "./auth";
import { AhuTestEstonia } from "../../types/ahu-estonia/AhuTestEstonia";
import { PurchaseOrder } from "../../types/ahu-estonia/AhuPurchaseOrder";
import { PurchaseOrderRow } from "../../types/directo/CanopyPurchaseOrder";
import {
  OzonatorCreateDto,
  OzonatorTestDto,
} from "../../types/ozonator/OzonatTestDto";
import { PurchaseOrderCommon } from "../../types/directo/PurchaseOrderCommon";
import { SepuCreateTestDto, SepuTest } from "../../types/sepu/SepuTest";
import {
  ControlPanelCreateTestDto,
  ControlPanelTest,
} from "../../types/control-panel/ControlPanelTest";

//purchaseorder
export const getOrderRequest = (orderNumber: string) => {
  return instance.get(`${apiUrl}/test/order?orderNumber=${orderNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getItemRequest = (itemNumber: string) => {
  return instance.get(`${apiUrl}/test/item?itemNumber=${itemNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const postTestRequest = (testDataToSend: DuctTestType) => {
  return instance.post(`${apiUrl}/test/test`, JSON.stringify(testDataToSend), {
    headers: { "Content-Type": "application/json" },
  });
};

//Directo Sweden
export const getProductionOrderRequest = (orderNumber: string) => {
  return instance.get(`${apiUrl}/test/se/order?orderNumber=${orderNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getItemRequestSE = (itemNumber: string) => {
  return instance.get(`${apiUrl}/test/se/item?itemNumber=${itemNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const postTestRequestSweden = (testDataToSend: DuctTestType) => {
  return instance.post(
    `${apiUrl}/test/se/test`,
    JSON.stringify(testDataToSend),
    { headers: { "Content-Type": "application/json" } }
  );
};

//Recair
export const postAhuTest = (testDataToSend: AhuElectricalMeasurements) => {
  return instance.post(
    `${apiUrl}/ahutest/save`,
    JSON.stringify(testDataToSend),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendAhuTestToDirecto = (ahuTestDto: AhuElectricalMeasurements) => {
  return instance.post(
    `${apiUrl}/test/ahu/post/event`,
    JSON.stringify(ahuTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const loadAhuTest = (testId: number) => {
  return instance.get(`${apiUrl}/ahutest/get?testId=${testId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCreatedAhuTests = (
  orderNumber: string,
  testType: string,
  snCode: string
) => {
  return instance.get(
    `${apiUrl}/ahutest/get/created?orderNumber=${orderNumber}&testType=${testType}&snCode=${snCode}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getAllOrderTests = (orderNumber: string) => {
  return instance.get(
    `${apiUrl}/ahutest/get/tests/all?orderNumber=${orderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createAhuTest = (
  ahuTestDto: AhuElectricalMeasurements,
  testType: string
) => {
  return instance.post(
    `${apiUrl}/ahutest/create?testType=${testType}`,
    JSON.stringify(ahuTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getSalesOrderRequest = (salesOrderNumber: string) => {
  return instance.get(
    `${apiUrl}/test/salesorder?orderNumber=${salesOrderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getPdf = (
  directoCode: string,
  rowNumber: string,
  documentType: string
) => {
  return instance.get<Blob>(
    `${apiUrl}/test/get/pdf?directoCode=${directoCode}&row=${rowNumber}&documentType=${documentType}`,
    { headers: { "Content-Type": "application/pdf" }, responseType: "blob" }
  );
};

export const deleteRecairAhuTest = (ahuTestId: number) => {
  return instance.delete<boolean>(`${apiUrl}/ahutest/delete?id=${ahuTestId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

//Recair end

//Karorifeer
export const getPurchaseOrderRequestTkal = (orderNumber: string) => {
  return instance.get(`${apiUrl}/test/order/tkal?orderNumber=${orderNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const createNewKalorifeerTest = (
  kalorifeerCommonTestData: Kalorifeer
) => {
  return instance.post(
    `${apiUrl}/kalorifeer/create/test`,
    JSON.stringify(kalorifeerCommonTestData),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const saveKalorifeerTest = (kalorifeerCommonTestData: Kalorifeer) => {
  return instance.post(
    `${apiUrl}/kalorifeer/save/test`,
    JSON.stringify(kalorifeerCommonTestData),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getSavedKalorifeerTests = (
  orderNumber: string,
  itemUniqueId: string
) => {
  const encodedItemUniqueId = encodeURIComponent(itemUniqueId); //otherwise '+' will be translated into empty space
  return instance.get(
    `${apiUrl}/kalorifeer/saved/all?orderNumber=${orderNumber}&itemUniqueId=${encodedItemUniqueId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getTkalTest = (orderNumber: string, itemUniqueId: string) => {
  return instance.get(
    `${apiUrl}/kalorifeer/tkal/test?orderNumber=${orderNumber}&itemUniqueId=${itemUniqueId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const finishKalorifeerTest = (kalorifeerCommonTestData: Kalorifeer) => {
  return instance.post(
    `${apiUrl}/kalorifeer/finish/test`,
    JSON.stringify(kalorifeerCommonTestData),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendTkalTestToDirecto = (testDto: Kalorifeer) => {
  return instance.post(
    `${apiUrl}/test/tkal/post/event`,
    JSON.stringify(testDto),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const updateTkalTest = (tkalUpdateDto: {
  purchaseOrderNumber: string;
  uniqueItemId: string;
  base64String: string;
}) => {
  return instance.post(
    `${apiUrl}/test/tkal/update/event`,
    JSON.stringify(tkalUpdateDto),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const removeTkalUnfinishedTest = (testId: number) => {
  return instance.delete<Kalorifeer[]>(
    `${apiUrl}/kalorifeer/remove?testId=${testId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

//Ahu Estonia start
export const getPurchaseOrderAhuEstonia = (orderNumber: string) => {
  return instance.get<PurchaseOrder>(
    `${apiUrl}/test/purchaseorder/ahu/estonia?orderNumber=${orderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendAhuEstTestToDirecto = (ahuTestDto: AhuTestEstonia) => {
  return instance.post<boolean>(
    `${apiUrl}/test/ahu/estonia/post/event`,
    JSON.stringify(ahuTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const saveAhuTest = (testDataToSend: AhuTestEstonia) => {
  return instance.post<boolean>(
    `${apiUrl}/ahutest/estonia/save`,
    JSON.stringify(testDataToSend),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const loadAhuEstTest = (testId: number) => {
  return instance.get<AhuTestEstonia>(
    `${apiUrl}/ahutest/estonia/get?testId=${testId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getAhuEstTestsForItem = (
  orderNumber: string,
  testType: string,
  uniqueItemCode: string
) => {
  return instance.get<AhuTestEstonia[]>(
    `${apiUrl}/ahutest/estonia/get/created?orderNumber=${orderNumber}&testType=${testType}&uniqueItemCode=${uniqueItemCode}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getAhuEstTestForOrder = (orderNumber: string) => {
  return instance.get<AhuTestEstonia[]>(
    `${apiUrl}/ahutest/estonia/get/tests/all?orderNumber=${orderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createAhuEstTest = (
  ahuTestDto: AhuTestEstonia,
  testType: string
) => {
  return instance.post<AhuTestEstonia>(
    `${apiUrl}/ahutest/estonia/create?testType=${testType}`,
    JSON.stringify(ahuTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const deleteAhuEstTest = (ahuTestId: number) => {
  return instance.delete<boolean>(
    `${apiUrl}/ahutest/estonia/delete?id=${ahuTestId}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
//Ahu Estonia end

//Canopy start
export const getPurchaseOrderRequest = (orderNumber: string) => {
  return instance.get(
    `${apiUrl}/test/get/purchaseorder?orderNumber=${orderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

export const getRecipes = (recipeCodes: PurchaseOrderRow[]) => {
  return instance.post(
    `${apiUrl}/test/get/recipes`,
    JSON.stringify(recipeCodes),
    { headers: { "Content-Type": "application/json" } }
  );
};
//Canopy end

//Ozonator start
export const getOzonatorPurchaseOrder = (
  orderNumber: string,
  category: "ozonator" | "controlPanel"
) => {
  return instance.get(
    `${apiUrl}/test/get/ozonator/purchaseorder?orderNumber=${orderNumber}&category=${category}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const getOzonatorTest = (id: number) => {
  return instance.get<OzonatorTestDto>(`${apiUrl}/ozonatortest/get?id=${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getSavedOzonatorTests = (
  orderNumber: string,
  uniqueItemCode: string
) => {
  return instance.get<OzonatorTestDto[]>(
    `${apiUrl}/ozonatortest/get/saved?purchaseOrderNumber=${orderNumber}&uniqueItemCode=${uniqueItemCode}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const createOzonatorTest = (ozonatorTestDto: OzonatorCreateDto) => {
  return instance.post(
    `${apiUrl}/ozonatortest/create`,
    JSON.stringify(ozonatorTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const saveOzonatorTest = (ozonatorTestDto: OzonatorCreateDto) => {
  return instance.post(
    `${apiUrl}/ozonatortest/save`,
    JSON.stringify(ozonatorTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const finishOzonatorTest = (ozonatorTestDto: OzonatorCreateDto) => {
  return instance.post(
    `${apiUrl}/ozonatortest/finish`,
    JSON.stringify(ozonatorTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const deleteOzonatorTest = (id: number) => {
  return instance.delete<boolean>(`${apiUrl}/ozonatortest/delete?id=${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
//Ozonator end

//SEPU start
export const GetUniversalPurchaseOrder = (purchaseOrderNumber: number) => {
  return instance.get<PurchaseOrderCommon>(
    `${apiUrl}/test/universal/purchase-order?purchaseOrderNumber=${purchaseOrderNumber}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const SaveSepuTest = (sepuTestDto: SepuTest) => {
  return instance.post(`${apiUrl}/seputest/save`, JSON.stringify(sepuTestDto), {
    headers: { "Content-Type": "application/json" },
  });
};
export const CreateSepuTest = (testDto: SepuCreateTestDto) => {
  return instance.post(`${apiUrl}/seputest/create`, JSON.stringify(testDto), {
    headers: { "Content-Type": "application/json" },
  });
};
export const FinishSepuTest = (testDto: SepuTest) => {
  return instance.post(`${apiUrl}/seputest/finish`, JSON.stringify(testDto), {
    headers: { "Content-Type": "application/json" },
  });
};
export const GetSepuTest = (id: number) => {
  return instance.get<SepuTest>(`${apiUrl}/seputest/get?id=${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
/* export const GetSepuDimensions = (itemCode: string, itemName: string) => {
  return instance.get(`${apiUrl}/seputest/dimensions/get?itemCode=${itemCode}&itemName=${itemName}`, { headers: { "Content-Type": "application/json" } })
} */
export const GetSavedSepuTests = (
  orderNumber: string,
  uniqueItemCode: string
) => {
  return instance.get<SepuTest[]>(
    `${apiUrl}/seputest/get/saved?purchaseOrderNumber=${orderNumber}&uniqueItemCode=${uniqueItemCode}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const DeleteSepuTest = (id: number) => {
  return instance.delete<boolean>(`${apiUrl}/seputest/delete?id=${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
//SEPU end

//Control panel test
export const CreateControlPanelTest = (
  createTestDto: ControlPanelCreateTestDto
) => {
  return instance.post<ControlPanelTest>(
    `${apiUrl}/control-panel/create`,
    JSON.stringify(createTestDto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const SaveControlPanelTest = (dto: ControlPanelTest) => {
  return instance.post<boolean>(
    `${apiUrl}/control-panel/save`,
    JSON.stringify(dto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const FinishControlPanelTest = (dto: ControlPanelTest) => {
  return instance.post<boolean>(
    `${apiUrl}/control-panel/finish`,
    JSON.stringify(dto),
    { headers: { "Content-Type": "application/json" } }
  );
};
export const GetControlPanelTest = (id: number) => {
  return instance.get<ControlPanelTest>(
    `${apiUrl}/control-panel/get?id=${id}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const GetSavedControlPanelTests = (
  orderNumber: string,
  uniqueItemCode: string
) => {
  return instance.get<ControlPanelTest[]>(
    `${apiUrl}/control-panel/get/saved?purchaseOrderNumber=${orderNumber}&uniqueItemCode=${uniqueItemCode}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
export const DeleteControlPanelTest = (id: number) => {
  return instance.delete<boolean>(`${apiUrl}/control-panel/delete?id=${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};
//Control panel test

//Duct rigidity
export const GetDuctRigidty = (itemDiameter: number) => {
  return instance.get<DuctRigidity>(
    `${apiUrl}/test/get/duct-rigidity?itemDiameter=${itemDiameter}`,
    { headers: { "Content-Type": "application/json" } }
  );
};
//Duct rigidity
