import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import InfoModal from '../../ahu-estonia/InfoModal';
import { CheckboxOptionsThumbsOnly, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from '../common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from '../common/MeasurementFieldOzonator';
import { ozoneGeneratorSafetyInstructions } from '../ModalTexts';
import Diak from '../../../../graphics/ozone-generator/diiak.jpg'
import SolderingSilicone from '../../../../graphics/ozone-generator/jootekohtadel_silikoon.png'
import TransistorsSecured from '../../../../graphics/ozone-generator/jõutransistorid_kinni.jpg'
import TransistorScrews from '../../../../graphics/ozone-generator/jõutransistorid_kruvid.png'
import WiresSecured from '../../../../graphics/ozone-generator/juhtmed_kaabliga_kinni.png'
import WiresGlued from '../../../../graphics/ozone-generator/juhtmetel_liim.png'
import CeramicPlatesVisual from '../../../../graphics/ozone-generator/koroonaplaadid_visual.png'
import ThermicMaterial from '../../../../graphics/ozone-generator/termiline_materjal.jpg'
import Bushings from '../../../../graphics/ozone-generator/tugipuksid.png'
import { useTkalDataContext } from '../../../../context/tkalData';

const OzGenVisualTestForm = () => {
    const { t } = useTranslation();
    const { ozonatorTest } = useTkalDataContext();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Visuaalne kontroll")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="flexboxAlignedRow" style={{ gap: "20px", marginBottom: "4%" }}>
                    <Label className="grid-item-label" style={{ width: "100%", marginLeft: "0" }}>{t("Tutvuge ohutusjuhendiga")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ozoneGeneratorSafetyInstructions} />
                </div>

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tugi-puksid on kontrollitud")}</Label>
                    <InfoModal picture={Bushings} title={t("Juhend")} bodyText={"Kõik keraamiliste plaatide tugi-puksid on olemas ja õige pikkusega, plaatide vaheline kaugus on 23 mm"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="bushings-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="bushings-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Jootekohtadel on silikoon")}</Label>
                    <InfoModal picture={SolderingSilicone} title={t("Juhend")} bodyText={"Jootekohtadel on silikoon."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="soldering-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="soldering-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Keraamilised plaadid on korras")}</Label>
                    <InfoModal picture={CeramicPlatesVisual} title={t("Juhend")} bodyText={"Keraamilised plaadid on terved, tükke pole väljas."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ceramicPlates-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="ceramicPlates-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Jõutransistorid tootja poolt kinni")}</Label>
                    <InfoModal picture={TransistorsSecured} title={t("Juhend")} bodyText={"Õrnalt liigutades on jõutransistorid kinni"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="transistors-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="transistors-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Jõutransistorite kinnituskruvid on üle pingutatud")}</Label>
                    <InfoModal picture={TransistorScrews} title={t("Juhend")} bodyText={"Jõutransistorite kinnituskruvide pingutamiseks on need esmalt vaja veidi lahti keerata (ei või täitsa lahti keerata), seejärel lisada (alt poolt) tilk keermeliimi ning seejärel taas kruvid kinni keerata. Kinnituskruvide pingutamisel, tuleb kruvid kinni keerata ~0,7 Nm jõuga (MAKITA DF012D positsioonil 4)."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="transistorScrews-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="transistorScrews-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Termiline materjal on olemas")}</Label>
                    <InfoModal picture={ThermicMaterial} title={t("Juhend")} bodyText={"Jõutransistorite ja radiaatori vahel on olemas sine termiline materjal."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="thermalPaste-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="thermalPaste-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Diiak on olemas")}</Label>
                    <InfoModal picture={Diak} title={t("Juhend")} bodyText={"Diiak on olemas"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="diak-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="diak-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Juhtmetel on liim olemas")}</Label>
                    <InfoModal picture={WiresGlued} title={t("Juhend")} bodyText={"Juhtmete (must ja punane) fikseerimiseks on pandud liimi."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="wireGlue-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="wireGlue-ozGen" showValue={false} />

                {/* Näita seda ainult siis kui eelmine valik on fail */}
                {(ozonatorTest.measurements['wireGlue-ozGen']?.statusNew === "FAIL") &&
                    <>
                        <div className="ozTest-form__label-info-button--grid">
                            <Label className="grid-item-label" >{t("Juhtmed on kaablisidemega kinnitatud")}</Label>
                            <InfoModal picture={WiresSecured} title={t("Juhend")} bodyText={"Juhtmetel puudus liim ning juhtmed fikseeriti kaablisidemega"} />
                            <CheckboxGroupOzonator
                                options={CheckboxOptionsThumbsOnly}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="wiresFixed-ozGen"
                            />
                        </div>
                        <MeasurementFieldOzonator propertyName="wiresFixed-ozGen" showValue={false} />
                    </>
                }
            </div>
        </form>
    )
}

export default OzGenVisualTestForm